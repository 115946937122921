import { getGraphQLEndpoint } from "./config";

// Funktion zum Handling des Request mit der GraphQL-API
export function httpRequest(schema,ifToken=true){
  return new Promise((resolve,reject)=>{
    let h = new Headers();
    h.append("Content-Type", "application/json");
    h.append("Accept","application/json");
    if(ifToken){
      h.append("Authorization",`Bearer ${getCookie("token")}`);
    }
    let res = fetch(getGraphQLEndpoint(),{
      method : "POST",
      headers : h,
      body : JSON.stringify({
        query : schema,
        variables : {}
      })
    }).then(response => response.json()).catch(err => {throw new Error(err)});
    resolve(res);
  })
}

// Funktion zum Einloggen mit Benutzername/Passwort
export function getLogin(user,pass){
  let loginSchema = `mutation LOGIN_WITH_PWD { auth { login( login:"${user}", password:"${pass}" ) { authToken userID userName expirationTime } } }`;
  return httpRequest(loginSchema,false);
}

// Funktion zum Einloggen mit Token aus dem CT
export function getLoginWithToken(tk){
  let loginSchema = `mutation LOGIN_WITH_TOKEN { auth { loginWithToken( token:"${tk}") { authToken userID userName expirationTime } } }`;
  return httpRequest(loginSchema,false);
}

// Gibt die Jobliste zurück falls verfügbar
export function getJobs(){
  let stat = "";
  if (localStorage.getItem('jobs')===null){
    updateJobs();
  }
  stat = JSON.parse(localStorage.getItem('jobs'));
  return stat;
}

// Pollt nach neuen Jobs und speichert sie im localstorage 
export async function updateJobs(){
  let stat = await getSummary();
  localStorage.setItem('jobs',JSON.stringify(stat.data.summary));
}

export async function filterJobs(){
  let allJobs = JSON.parse(localStorage.getItem('jobs'));
  let slot = Number(sessionStorage.getItem('slot'));
  let filtered = await getFilteredJobs("SHOP",slot,"VX",false);
  //console.log(filtered);
  //console.log(allJobs);
  allJobs.photoRatingCounts = filtered.data.summaryFiltered.photoRatingCounts;
  allJobs.photoReratingCounts = filtered.data.summaryFiltered.photoReratingCounts;
  allJobs.videoRatingCounts = filtered.data.summaryFiltered.videoRatingCounts;
  allJobs.videoReratingCounts = filtered.data.summaryFiltered.videoReratingCounts;
  return allJobs;
}

// Wählt die erste verfügbare Unterkategorie aus einer Hauptkategorie (cat) aus.
export function getFirstCat(cat){
  let set = [];
  let stats = getJobs();
  if(cat === 1){
    set = stats.photoRatingCounts;
  }else if(cat === 2){
    set = stats.photoReratingCounts;
  }else if(cat === 3){
    set = stats.videoRatingCounts;
  }else if(cat === 4){
    set = stats.commentRatingCounts.concat(stats.textRatingCounts.find(i=>i.type==="VX"));
  }else{
    return 0;
  }
  let n = -1;
  for(let i=0;i<set.length;i++){
    if(set[i].counts > 0){
      n = set[i].type;
      break;
    }
  }
  if(n === -1){
    n = set[0].type;
  }
  return n;
}

// Hilfsfunktion: Konvertiert von HH:MM:SS in Sekunden 
export function convertTimeStringToInt(val){
  val = val.split(":");
  return Number(val[0])*3600 + Number(val[1])*60 + Number(val[2]);
}

// Hilfsfunktion: Gibt den String mit einem Großbuchstaben am Anfang zurück für mehr Varianten in der Badwordssuche
export function capitalizeFirstLetter(val){
  return val.charAt(0).toUpperCase() + val.slice(1);
}

// Hilfsfunktion zum setzen von Cookies
export function setCookie(name,value){
  //const date = new Date();
  //date.setTime(date.getTime() + (validHours * 3600000));
  //let expires = "expires=" + date.toUTCString();
  document.cookie = `${name}=${value}`;// ${expires}`;
}

// Hilfsfunktion zum lesen von Cookies
export function getCookie(name){
  const allC = document.cookie;
  return allC.split("; ").find(c => c.startsWith(name))?.split("=")[1] ?? null;
}

// Hilfsfunktion zum Entfernen von Cookies
export function removeCookie(name){
  document.cookie = `${name}=${null}; 0`;
}

// Query für das Jobpolling
export function getSummary(){
  let schema =
  `query SUMMARY{
                  summary { 
                    photoRatingCounts { 
                      type label counts 
                    }
                    videoRatingCounts {
                      type label counts
                    }
                    photoReratingCounts {
                        type label counts
                    }
                    videoReratingCounts {
                      type label counts
                    }
                    textRatingCounts {
                      type label counts
                    }
                    commentRatingCounts {
                      type label counts
                    }
                    modelVerificationCounts {
                      type label counts
                    } 
                    lustagentenRatingCounts {
                      type label counts
                    }
                    communityNicknames {
                      type label counts
                    }
                    loginFitness {
                      type label counts
                    }
                    telegramBroadcasts {
                      type label counts
                    }
                    powerMailing {
                      type label counts
                    }
                    service0900 {
                      type label counts
                    }
                    fsk16Chats {
                      type label counts
                    }
                    liveStreams {
                      type label counts
                    }
                  }
                }`;
  return httpRequest(schema);
}

export function getFilteredJobs(secondaryCat,slot,product,premiumonly){
  let schema =
  `query SUMMARY {
    summaryFiltered: summary(
      #mediaType: ${secondaryCat}
      slot: ${slot}
      #product: ${product}
      #premiumModelsOnly: ${premiumonly}
    ) {
      photoRatingCounts { 
        type label counts 
      }
      videoRatingCounts {
        type label counts
      }
      photoReratingCounts {
          type label counts
      }
      videoReratingCounts {
        type label counts
      }
    }
  }`;
  return httpRequest(schema);
}

// öffnet Lustagenten mit einem Autologin in einem neuen Fenster mit Autologin
export async function loginToLustagenten(){
  let l = await getLustagentenAutologinUrl();
  if(l.data.auth.lustagentenAutologinUrl === null){
    window.open("https://www.lustagenten.com/pageadmin/","_blank");
  }else{
    window.open(l.data.auth.lustagentenAutologinUrl,"_blank");
  }
}

export async function loginToTelegram(){
    window.open("https://vxadmin.ops.xxx/CONTROLVX/Telegram/broadcasts","_blank")
}

// Mutation um einen Autologinlink zu Lustagenten zu erhalten
export function getLustagentenAutologinUrl(){
  let schema =
  `mutation LA_AUTOLOGIN_URL {
    auth {
      lustagentenAutologinUrl
    }
  }`;
  return httpRequest(schema);
}

// Query um das nächste Photoalbum zu erhalten
export function getNextAlbum(searchAlbum,searchUser,secCat,mode,sort,slot,premium){
  let schema =
  `query PHOTOALBUM_RATING {
      photoalbumRating(
      ${(searchAlbum === 0 ? "#" : "")}albumId: ${searchAlbum}
      ${(searchUser === 0 ? "#" : "")}userId: ${searchUser}
      ${(((searchAlbum !== 0) || (searchUser !== 0)) ? "#" : "")}ratingGroup: ${secCat}
      ratingMode: ${mode}
      skippedIds: ${sessionStorage.getItem('skipIds')??"[]"}
      ${(slot === -1 ? "#" : "")}slot: ${slot}
      premiumModelsOnly: ${premium}
      sortDescending:  ${sort}
      ) {
      photoalbum {
    albumId
    userId
    albumFlags
    createDate
    ratingGroup
    type
    typeName
    status
    statusName
    picturesTotal
    picturesCheckedTotal
    picturesRejectedTotal
    price
    maxPrice
    priceRange
    titles {
      language
      text
    }
    descriptions {
      language
      text
    }
    allTags
    newTags
    pictures {
      pictureId
      firstRatingDate
      firstRatingName
      position
      width
      height
      fsk
      isChecked
      isRechecked
      type
      typeName
      url
      urlBig
      flags
      allTags
      newTags
    }
    suspectedWords
    }
    account {
      userId
      userName
      lockType
      lockTypeName
      isVerified
      isNew
      isInternal
      signupDate
      profileGender
      profileSexuality
      profileCategory
      accountManager
      salesManager
      isActiveAccounting
      hasTVLiveShow
      platforms
      isPremiumCooperation
      mainComment
      privilegeComment
      avatarUrl
      profilePictureUrls
      documents{
        userId
        urls
      }
    }
    allowedSlots
    allowedPictureFlags
    allowedLanguages
    allowedPictureRejectionReasons{
      id
      label
      textDE
      textEN
    }
    allowedAlbumRejectionReasons{
      label
      id
      textDE
      textEN
    }
    isTextVisible
    isTagsVisible
    isPriceVisible
    isButtonRejectAllowed
    isButtonDeleteAllowed
    isButtonHideAllowed
    isButtonSaveAllowed
    isAlbumFlagsVisible
    skippedIds
    } 
  }`;
  return httpRequest(schema);
}

// Mutatuon um ein Photoalbum zu speichern
export function savePhotoalbum(album,ratCat,pics){
  let schema =
  `mutation SAVE_PHOTOALBUM {
    photoalbumRating {
      saveAlbum(
        photoalbumInput: {
          ratingMode: ${(ratCat === 1 ? "check" : "recheck")},
          albumId: ${album.photoalbum.albumId},
          price: ${album.photoalbum.price},
          maxPrice: ${album.photoalbum.maxPrice},
          priceAutorecalcForbidden: true,
          tags: ${JSON.stringify(album.photoalbum.allTags)},
          flags: [${(album.aktion365 ? "calendar365" : "")}],
          titlesVisitx: ${JSON.stringify(album.photoalbum.titles).replace(/"language":/g,"language:").replace(/"text":/g,"text:")},
          descriptionsVisitx: ${JSON.stringify(album.photoalbum.descriptions).replace(/"language":/g,"language:").replace(/"text":/g,"text:")},
          pictures: [${pics.map(i=>`{
            pictureId: ${i.pictureId},
            isRejected: ${i.fsk<12},
            rejectionReason: [${(i.fsk>11?'{language:"DE",text:""},{language: "EN",text:""}':
            `{language:"DE",text:"${album.allowedPictureRejectionReasons.find(r=>r.id === i.rejectionId).textDE}"},
            {language:"EN",text:"${album.allowedPictureRejectionReasons.find(r=>r.id === i.rejectionId).textEN}"}`)}],
            fsk: ${(i.fsk>11 ? i.fsk : 18)},
            tags: [${i.allTags}],
            flags: [${i.flags}]
          }`)}]
          }
        )
      }
    }`;
    return httpRequest(schema);
}

// Mutatuon um ein Photoalbum zu verstecken
export function hidePhotoalbum(album){
  let schema =
  `mutation HIDE_PHOTOALBUM {
    photoalbumRating {
      hideAlbum (
        albumId: ${album.photoalbum.albumId}
      )
    }
  }`;
  return httpRequest(schema);
}

// Mutatuon um ein Photoalbum zu löschen
export function deletePhotoalbum(album,comment){
  let schema =
  `mutation DELETE_PHOTOALBUM {
    photoalbumRating {
      deleteAlbum (
        albumId: ${album.photoalbum.albumId},
        comment: ${comment}
      )
    }
  }`;
  return httpRequest(schema);
}

// Mutatuon um ein Photoalbum abzulehnen
export function rejectPhotoalbum(album,descDE,descEN){
  let schema =
  `mutation REJECT_PHOTOALBUM {
    photoalbumRating {
      rejectAlbum (
        albumId: ${album.photoalbum.albumId}
        rejectionReason: [{language:"DE",text:"${descDE}"},{language:"EN",text:"${descEN}"}]
      )
    }
  }`;
  return httpRequest(schema);
}

// Query für das nächste Videoalbum
export function getNextVideo(searchAlbum,searchUser,secCat,sort,slot,premium,mode){
  let schema =
  `query VIDEO_RATING {
    videoRating(
      ${(searchAlbum === 0 ? "#" : "")}albumId: ${searchAlbum}
      ${(searchUser === 0 ? "#" : "")}userId: ${searchUser}
      ${(((searchAlbum !== 0) || (searchUser !== 0)) ? "#" : "")}ratingGroup: ${secCat}
      ratingMode: ${mode}
      skippedIds: ${sessionStorage.getItem('skipIds')??"[]"}
      ${(slot === -1 ? "#" : "")}slot: ${slot}
      premiumModelsOnly: ${premium}
      sortDescending:  ${sort}
      ) {
      video {
        albumId
        videoId
        userId
        createDate
        ratingGroup
        type
        typeName
        status
        statusName
        width
        height
        fsk
        isChecked
        isRechecked
        audioType
        audioQuality
        videoQuality
        salesCount
        lastRatingDate
        lastRatingName
        previewPictureIdFsk16
        previewPictureIdFsk18
        plannedReleaseDate
        price
        maxPrice
        priceRange
        flags
        urls {
          format
          url
        }
        titles {
          language
          text
        }
        descriptions {
          language
          text
        }
        allTags
        newTags
        pictures {
          pictureId
          position
          width
          height
          fsk
          isChecked
          isRechecked
          type
          typeName
          url
          urlBig
          flags
          allTags
          newTags
        }
        suspectedWords
        trailer {
          startFsk16
          endFsk16
          startFsk18
          endFsk18
        }
       }
       account {
         userId
         userName
         lockType
         lockTypeName
         isVerified
         isInternal
         isNew
         hasGemaProof
         signupDate
         profileGender
         profileSexuality
         profileCategory
         accountManager
         salesManager
         isActiveAccounting
         hasTVLiveShow
         platforms
         isPremiumCooperation
         mainComment
         privilegeComment
         avatarUrl
         profilePictureUrls
         documents {
          userId
          urls
          firstname
          lastname
         }
       }
       allowedLanguages
       allowedSlots
       allowedVideoFlags
       allowedRejectionReasons {
         id
         label
         textDE
         textEN
       }
       isTagsVisible
       isPriceVisible
       isButtonRejectAllowed
       isButtonSkipAllowed
       isButtonDelayAllowed
       isButtonRetranscodeAllowed
       skippedIds
       allowedAudioQualityValues
       allowedVideoQualityValues
       allowedAudioTypeValues
       allowedVideoTypes {
        id
        label
       }
       allowedContests{
        id
        label
      }
    } 
  }`;
  return httpRequest(schema);
}

// Mutation um ein Videoalbum zu speichern
export function saveVideo(vid,appStatus,rejres,ratCat){
  let schema =
  `mutation VIDEO_RATING {
    videoRating {
      saveVideo(
        videoInput: {
          rejectionReason: ${rejres},
          ratingMode: ${(ratCat === 3 ? "check" : "recheck")},
          albumId: ${vid.video.albumId},
          albumType: "${vid.video.type}",
          contestId: ${(appStatus.selectedContest === "" ? null : appStatus.selectedContest)},
          previewPictureId16: ${(appStatus.previewPictureStats.findIndex(p=>p.previewPic===16)===-1? null : vid.video.pictures[appStatus.previewPictureStats.findIndex(p=>p.previewPic===16)].pictureId)},
          previewPictureId18: ${(appStatus.previewPictureStats.findIndex(p=>p.previewPic===18)===-1? null : vid.video.pictures[appStatus.previewPictureStats.findIndex(p=>p.previewPic===18)].pictureId)},
          price: ${vid.video.price},
          maxPrice: ${vid.video.maxPrice},
          audioQuality: ${(appStatus.audioQuality === 1 ? "none" : "good")}, 
          audioType: ${(appStatus.audioQuality === 1 ? "other" : (appStatus.audioQuality === 0 ? "noise" : "music"))}, 
          fsk: ${appStatus.selectedRating},
          trailerStart16: ${convertTimeStringToInt(appStatus.trailerFSK16Start)},
          trailerEnd16: ${convertTimeStringToInt(appStatus.trailerFSK16End)},
          trailerStart18: ${convertTimeStringToInt(appStatus.trailerFSK18Start)},
          trailerEnd18: ${convertTimeStringToInt(appStatus.trailerFSK18End)},
          tags: ${JSON.stringify(vid.video.allTags)},
          flags: [${appStatus.selectedFlags}],
          titlesVisitx: ${JSON.stringify(vid.video.titles).replace(/"language":/g,"language:").replace(/"text":/g,"text:")},
          descriptionsVisitx: ${JSON.stringify(vid.video.descriptions).replace(/"language":/g,"language:").replace(/"text":/g,"text:")},
          pictures: [${appStatus.previewPictureStats.map(p => `{
            pictureId : ${p.pid},
            isRejected : false,
            fsk : ${p.rating},
            tags : [],
            flags : []
          }`)}]
        }
      )
    }
  }`;
  return httpRequest(schema);
}

export function delayVideo(albumId){
  let schema =
  `mutation DELAY_VIDEO {
    videoRating {
      delayVideo(albumId: ${albumId})
    }
  }`;
  return httpRequest(schema);
}

export function retranscodeVideo(albumId){
  let schema =
  `mutation RETRANSCODE_VIDEO {
    videoRating {
      retranscodeVideo(albumId: [${albumId}])
    }
  }`;
  return httpRequest(schema);
}

// Query um Kommentare zu erhalten
export function getNextComments(ty,st,nt,lt,pg,usr,hst){
  let schema =
  `query COMMENT_RATING {
      commentRating(
        type: ${ty},
        status: ${st},
        ${(nt==="all" ? "#note: all" : `note: ${nt}`)},
        ${(usr===0 || usr === "" ?"#":"")}guestId: ${usr},
        ${(hst===0 || hst === "" ?"#":"")}modelId: ${hst},
        offset: ${pg*lt},
        limit: ${lt}
      ) {
        comments {
          commentId
          date
          albumId
          modelId
          modelName
          forwarderId
          forwarderName
          recipientId
          recipientName
          type
          status
          note
          replyOn
          text
          suspectedWords
        }
        type
        status
        note
        totalUnratedComments
        offset
        limit
        guestId
      }
    }`;
  return httpRequest(schema);
}

// Mutation um Kommentare zu bestätigen
export function approveComments(commIds){
  let schema =
  `mutation APPROVE_COMMENT {
      commentRating {
        approveComments(commentIds: [${commIds}])
      }
    }`;
    return httpRequest(schema);
}

// Mutation um Kommentare abzulehnen
export function deleteComments(commIds){
  let schema =
  `mutation DELETE_COMMENT {
      commentRating {
        deleteComments(commentIds: [${commIds}])
      }
    }`;
    return httpRequest(schema);
}

// Query um Freitexte zu erhalten
export function getNextTexts(pr,st,lt,usr){
  let schema =
  `query TEXT_RATING {
    textRating(
      status: ${st}
      limit: ${lt}
      ${(pr==="ALL"?"#":"")}product: ${pr}
      ${(usr===""?"#":"")}modelId: ${usr}
    ) {
      allowedRejectionReasons {
        id
        label
        textDE
        textEN
      }
      status
      limit
      modelId
      totalTexts
      texts {
        account {
          userId 
          userName 
          avatarUrl
          mainComment 
        }
        allowedLanguages
        product
        textType
        textTypeName
        title {
          language
          text
        }
        text {
          language
          text
        }
        status
        rejectionReason
        suspectedWords
      }
    }
  }`;
  return httpRequest(schema);
}

// Mutation um Freitexte zu speichern
export function saveTexts(txt,appStatus){
  console.log(txt);
  let schema =
  `mutation RATE_TEXT {
    textRating {
      rateText(
        input: [${txt.map((t,ind)=>`
        {product: ${t.product}
        userId: ${t.account.userId}
        textType: ${t.textType}
        isRejected: ${appStatus.rating[ind] === "accepted" ? "false" : "true"}
        ${appStatus.rating[ind] === "accepted" ? "#" : ""}rejectionReason: ${appStatus.rating[ind]}
        ${appStatus.rating[ind] === "accepted" ? "" : "#"}texts: ${JSON.stringify(t.title).replace(/"language":/g,"language:").replace(/"text":/g,"text:")}
        ${appStatus.rating[ind] === "accepted" ? "" : "#"}titles: ${JSON.stringify(t.text).replace(/"language":/g,"language:").replace(/"text":/g,"text:")}
        }`)}]
      )
    }
  }`;
  return httpRequest(schema);
}

// Query um eine Modelverification zu erhalten
export function getNextVerification(searchString,showRejected,sort){
  let schema =
  `query MODEL_VERIFICATION_SUPPORT {
    modelVerificationSupport(
      showRejected: ${showRejected}
      sortDescending : ${sort}
      skippedIds:  ${sessionStorage.getItem('skipIds')??"[]"}
      ${searchString === "" ? "#" : ""}searchValue: "${searchString}"
    ) {
      totalModelsForRating
      totalRejectedModels
      account {
        userId
        userName
        originPlatform
        isVerified
        isInternal
        isNew
        signupDate
        profileGender
        profileSexuality
        profileCategory
        accountManager
        salesManager
        isActiveAccounting
        platforms
        isPremiumCooperation
        mainComment
        privilegeComment
        avatarUrl
        profilePictureUrls
      }
      lastRatingInfo
      idCards {
        id
        userId
        type
        typeName
        side
        sideName
        status
        statusName
        url
      }
      idShots {
        id
        userId
        type
        typeName
        side
        sideName
        status
        statusName
        url
      }
      mrfs {
        id
        userId
        type
        typeName
        side
        sideName
        status
        statusName
        url
      }
    } 
  }`;
  return httpRequest(schema);
}

// Mutation um eine Verifikation zu bestätigen
export function approveVerification(id){
  let schema =
  `mutation MODEL_VERIFICATION_SUPPORT_ACCEPT {
    modelVerificationSupport {
      verifyModel(userId: ${id})
    }
  }`;
  return httpRequest(schema);
}

// Mutation um eine Verifikation abzulehnen
export function rejectVerification(id,reason){
  let schema =
  `mutation MODEL_VERIFICATION_SUPPORT_REJECT {
    modelVerificationSupport {
      rejectModel(
        userId: ${id}
        comment: "${reason}"
        )
    }
  }`
  return httpRequest(schema);
}

// Query um Fsk16Chats zu erhalten
export function getNextFsk16Chats(lt,pg,from,to){
  let schema =
  `query FSK16_CHATS {
    fsk16ChatsReview(
      offset: ${pg*lt}
      limit: ${lt}
      type:fsk16
      ${from===""?"#":""}fromDate: "${from}"
      ${to===""?"#":""}toDate: "${to}"
    ) {
  type
      fromDate
      toDate
      offset
      limit
      isAllowedToForbidFSK16Chat
      
      totalItems
      items {
        isAlreadyForbidden
        chatId
        modelId
        modelName
        guestId
        guestName
        startDate
        duration
        type
        snapshots
      }
    }
  }`;
  return httpRequest(schema);
}

// Mutation um den Fsk16 Chat eines Models zu verbieten
export function forbidFsk16Chat(uid,cid){
  let schema =
  `mutation FSK16_CHAT_REVIEW {
    fsk16ChatReview {
      forbidSoftChat(
        userId: ${uid}
        chatId: ${cid}
      ) 
    }
  }`;
  return httpRequest(schema);
}

// Query um einige Livestreams zu erhalten
export function getNextLivestreams(limit,page,type){
  let schema =
  `query LIVE_STREAMS {
    liveStreams(
      offset: ${page*limit}
      limit: ${limit}
      type: ${type}
    ) {
      type
      offset
      limit
      totalItems
      allowedEmailTemplates {
        id
        label
      } 
      items {
        streamId
        streamType
        modelId
        modelName
        isOnline
        url
        emailSentDate
        isReportedAsBadStream
      }
    } 
  }`;
  return httpRequest(schema);
}

// Mutation um eine Email ans Model zu senden im Livestreammonitor
export function liveStreamSendEmail(uid,templ){
  let schema =
  `mutation LIVE_STREAM_SENDMAIL {
    liveStreamRating {
     sendEmail(
       userId: ${uid}, 
       emailTemplateId: ${templ}
     )
   }
 }`;
  return httpRequest(schema);
}

// Mutation um einen Badstreamreport zu senden im Livestreammonitor
export function liveStreamBadStream(sid){
  let schema =
  `mutation LIVE_STREAM_REPORT {
    liveStreamRating {
      reportBadStream(
        streamId: "${sid}"
      )
    }
  }`;
  return httpRequest(schema);
}

export function getDatingCheck(userId){
  let schema =
  `query NICKNAME_CHECK {
    communityNicknameCheck(
      offset:0 
      limit: 30 
      ${userId===""?"#":""}userId: ${userId}
    ) {
      totalItems
      filter {
        userId
        offset
        limit
      }
      items {
        account {
          userId
          userName
        }
        nickname
        status
        changedAt
      } 
    }
  }`;
  return httpRequest(schema);
}

export function rateDatingCheck(users){
  let schema = 
  `mutation COMMUNITY_NICKNAME_CHECK {
    communityNicknameCheck {
      rateNicknames(input:  [ ${users.map((i,ind)=>`
      {
        userId: ${i.id},
        isNicknameAccepted: ${i.accepted},
      },`)}
    ]
      )
    }
  }`;
  return httpRequest(schema);
}

export function getLoginFitness(onlineStatus,fskStatus,from,to,country){
  let schema = 
  `query LOGIN_FITNESS_RATING {
    loginFitness(
      ${onlineStatus===""?"#":""}onlineStatus: ${onlineStatus}
      ${fskStatus===""?"#":""}fskStatus: ${fskStatus}
      ${from===""?"#":""}registerDateStart: "${from}"
      ${to===""?"#":""}registerDateEnd: "${to}"
      ${country===""?"#":""}country: "${country}"
      offset:0 
      limit: 20
      sortDescending: false
    ) {
      filter {
        onlineStatus
        fskStatus
        country
        registerDateStart
        registerDateEnd
        offset
        limit
        sortDescending
        allowedFskStatus
        allowedOnlineStatus
      } 
      totalItems
      items {
        account {
          userId
          userName 
          country
          profileGender
          profileCategory
          lockType
          lockTypeName
        }
        fskFitness
        internationalFitness
      }
    }
  }`;
  return httpRequest(schema);
}

export function saveLoginFitness(logins){
  let schema = 
  `mutation LOGIN_FITNESS {
    loginFitness {
      rateLogins(
        loginFitnessInput: [ ${logins.items.map((i,ind)=>`
          {
            userId: ${i.account.userId},
            fskFitness: ${i.fskFitness},
            internationalFitness: ${i.internationalFitness}
          },`)}
        ]
      )
    }
  }`;
  return httpRequest(schema);
}

export function getPowermails(status,limit,page,pid,userId){
  let schema = `query POWER_MAIL_CHECK {
    powerMailingCheck(
      offset: ${limit*page}
      limit: ${limit}
      status: ${status}
      ${(pid === "" ? "#":"")}id: ${pid}
      ${(userId === "" ? "#":"")}userId: ${userId}
      ){
      totalItems
      filter {
        id
        userId
        status
        dateFrom
        dateTo
        offset
        limit
        allowedFskStatus
      } 
      items {
        account {
          userId 
          userName
        }
        id
        targetGroup
        recipientsCount
        createdAt
        sentAt
        subject
        text
        suspectedWords
      }
    }  
  }`;
  return httpRequest(schema);
}

export function ratePowermails(mails){
  let schema = `mutation POWER_MAILING_CHECK {
    powerMailingCheck {
      ratePowerMails(
        input: [ ${mails.map((i)=>`
          {
            id: ${i.id},
            isAccepted: ${i.isAccepted},
          },`)}
        ]      
      )
    }
  }`;
  return httpRequest(schema);
}

export function get0900(){
  let schema = `query SERVICE_0900 {
    service0900 {
      totalItems
      items {
        account {
          userId
          userName
        }
        targetNumber
        isTargetNumberVerified
        numbers0900 {
          product
          country
          phoneNumber
        }
      }
    }
  }`
  return httpRequest(schema);
}

export function rate0900(numbers){
  let schema = `mutation SERVICE0900_CHECK {
    service0900Check {
      ratePhoneNumber(
        input: [ ${numbers.map((i)=>`
          {
            userId: ${i.id},
            isAccepted: ${i.isAccepted}
          }`)}
        ]
      )
    }
  }`;
  httpRequest(schema);
}